// import { HeroSection, GlobeDemo, PostCard, ServicesCard, SkeletonLoader, Projects, OfficePic,  PartnerCard } from '@/components'
"use client"
import dynamic from 'next/dynamic';
import { SkeletonLoader } from "@/components"
const HeroSection = dynamic(() => import('@/components/HeroSection'));
const GlobeDemo = dynamic(() => import('@/components/Globe'));
const PostCard = dynamic(() => import('@/components/PostCard'));
const ServicesCard = dynamic(() => import('@/components/Services'));
// const SkeletonLoader = dynamic(() => import('@/components/SkeletonLoader'));
const Projects = dynamic(() => import('@/components/ProjectCards'));
const OfficePic = dynamic(() => import('@/components/OfficePic'));
const PartnerCard = dynamic(() => import('@/components/PartnerCard'));
const ShowReview = dynamic(() => import('@/components/ReviewSection/ReviewShow'));
const FlipWords = dynamic(() => import('@/components/ui/flip-words'));
// import ShowReview from '@/components/ReviewSection/ReviewShow';
// import { FlipWords } from '@/components/ui/flip-words';
import { Suspense } from 'react';
const HomeClientPage = () => {
  const service1 = 'https://res.cloudinary.com/dcwai4hwc/image/upload/v1729575830/webdev_eimzac.webp'
  const service2 = 'https://res.cloudinary.com/dcwai4hwc/image/upload/v1729575811/mobileApp_ctcq2c.webp'
  const service3 = 'https://res.cloudinary.com/dcwai4hwc/image/upload/v1729575804/business2_lg2v5k.webp'
  const service4 = 'https://res.cloudinary.com/dcwai4hwc/image/upload/v1729575830/UIUX_c8aece.webp'
  const service5 = 'https://res.cloudinary.com/dcwai4hwc/image/upload/v1732515253/ourservice_ejnkp3.webp'
  const CEO = 'https://res.cloudinary.com/dcwai4hwc/image/upload/v1729575804/CEO6_v2dd4x.webp'
  const ExecDirector = 'https://res.cloudinary.com/dcwai4hwc/image/upload/v1729575804/Director_rr01cr.webp'
  const Director = 'https://res.cloudinary.com/dcwai4hwc/image/upload/v1729575805/Director2_eytjsk.webp'
  const words = ["BackOfficeIntegration", "PowerBI", "Quick book", "Accounting Services","Oracle","Financial Solution","SAP BI/BW", "BAW", "SAP BW", " SAP FI/CO", "SAP ABAP", "dotnet", "MRI Software","dynamic 365","ERP"];
  
  
  return (
    <Suspense fallback={<SkeletonLoader/>}>
      <HeroSection />
      <Projects />
      <GlobeDemo />
      <h1
        id='services'
        className='text-4xl md:text-6xl bg-[#020617] text-white py-5 font-semibold text-center'>
        Premier Service Solutions
      </h1>
      <span   className='grid grid-cols-1 sm:grid-cols-2 px-2 max-w-7xl mx-auto  md:grid-cols-3 bg-black py-28 lg:grid-cols-5 gap-4  justify-items-center'>
        <ServicesCard  title={"Web Development"} url={service1} />
        <ServicesCard title={"Mobile Application"} url={service2} />
        <ServicesCard otherTitle={"Other Services"}  title={<FlipWords words={words}/>}  url={service5} />
        <ServicesCard   title={`UI/UX DESIGN`} url={service4} />
        <ServicesCard title={`B P O`} url={service3} />
       
      </span>
      <h2 className='text-4xl bg-[#020617] text-white md:text-6xl py-5 font-semibold text-center'>
        CEO & Directors
      </h2>
      <span className='grid grid-cols-1 max-w-7xl mx-auto bg-black text-white md:grid-cols-2 lg:grid-cols-3 h-full gap-8 py-20'>
         <PostCard imageUrl={CEO}  post={'CEO'} fullName={'Haris Yusuf'} /> 
        <PostCard imageUrl={ExecDirector} post={'The Founder & Executive Director '} fullName={'Nizamuddin Tai'} />
         <PostCard  imageUrl={Director} post={'Director'} fullName={'Hassan Yusuf'} /> 
      </span>

      <span>
        <h3 className='text-4xl bg-[#020617] text-white md:text-6xl pt-10 font-semibold text-center'>
          Where Hustle Meets Flow 
        </h3>
        <OfficePic />
      </span>
     
      <span>
      <h1 className='text-4xl bg-[#020617] text-white md:text-6xl pt-10 font-semibold text-center'>
         Reviews 
        </h1>
        <ShowReview/>
      </span>
      <h2 className='text-4xl bg-[#020617] text-white md:text-6xl pt-10 font-semibold text-center'>
         Trusted & Partner 
        </h2>
      <span className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 max-w-7xl lg:max-w-10xl mx-auto justify-items-center bg-black text-white  h-full gap-8 py-20'>
      <PartnerCard   message={'Strategic Partner (USA)'} imageUrl={'https://res.cloudinary.com/dcwai4hwc/image/upload/v1732088352/gt_mdhbtd.png'}/>
      <PartnerCard   message={'Strategic Partner (Bahrain)'} imageUrl={'https://res.cloudinary.com/dcwai4hwc/image/upload/v1732087011/nubitlink_twq4cm.png'}/>
      <PartnerCard   message={'Strategic Partner'} imageUrl={'https://res.cloudinary.com/dcwai4hwc/image/upload/v1731932930/rsrirnew_agpykx.png'}/>
      <PartnerCard   imageUrl={'https://res.cloudinary.com/dcwai4hwc/image/upload/v1731671635/pseb_i5gbzn.jpg'}/>
      <PartnerCard   imageUrl={'https://res.cloudinary.com/dcwai4hwc/image/upload/v1731933703/pasha-logo_zjyaor.png'}/>
      <PartnerCard   imageUrl={'https://res.cloudinary.com/dcwai4hwc/image/upload/v1731671595/kcci_logo_wnsea9.png'}/>
      </span>
      </Suspense>
  )
}

export default HomeClientPage
